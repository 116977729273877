.listwithus_banner {
  width: 100%;
  height: 70vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #d1d5db;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.upload-area:hover {
  background-color: #f9fafb;
}

.upload-preview {
  max-width: 100%;
  height: auto;
  margin-top: 12px;
}
