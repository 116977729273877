.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 30px !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 30px !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  scale: 0.8;
}

input[type="checkbox"],
input[type="radio"] {
  min-width: 20px !important;
  min-height: 20px !important;
}
/* Base checkbox styles */
input[type="checkbox"],
input[type="radio"] {
  appearance: none; /* Remove default appearance */
  -webkit-appearance: none; /* For Safari/Chrome */
  -moz-appearance: none; /* For Firefox */
  border: 2px solid #ccc; /* Default border color */
  border-radius: 4px; /* Optional rounded corners */
  outline: none; /* Remove focus outline */
  cursor: pointer; /* Add pointer cursor */
  display: inline-block;
  position: relative;
}
/* Checked checkbox styles */
input[type="checkbox"]:checked {
  background-color: #aca9a5; /* Custom checked background color */
  border-color: #aca9a5; /* Optional: Match border color */
}

/* Add a checkmark using pseudo-element */
input[type="checkbox"]:checked::after {
  content: "\2713"; /* Unicode for checkmark */
  font-size: 14px;
  color: white; /* Checkmark color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sticky-section {
  position: sticky;
  top: 10px; /* Adjust the top value as needed */
  max-height: calc(100vh - 20px); /* Optional: Limit the height */
  overflow-y: auto; /* Optional: Add scrolling for long content */
}

.sticky-section {
  position: sticky;
  top: 80px; /* Keeps the section at the top when scrolling */
  overflow-y: scroll; /* Enables vertical scrolling */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.sticky-section::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}
