@tailwind base;
@tailwind components;
@tailwind utilities;

@import "leaflet/dist/leaflet.css";

/* @font-face {
  font-family: "Futura";
  src: url("./Fonts/FUTURA45LIGHT.TTF") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url("./Fonts/FUTURA55REGULAR.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url("./Fonts/FUTURA65MEDIUM.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url("./Fonts/FUTURA75BOLD.TTF") format("truetype");
  font-weight: 600;
  font-style: normal;
} */

@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFPRODISPLAY/SFPRODISPLAYREGULAR.OTF") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFPRODISPLAY/SFPRODISPLAYMEDIUM.OTF") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./Fonts/SFPRODISPLAY/SFPRODISPLAYBOLD.OTF") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "SF Pro Display";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

ul.menu li a:hover,
ul.menu li a:focus {
  background-color: transparent;
}

.menu li > *:not(ul, .menu-title, details, .btn):active,
.menu li > *:not(ul, .menu-title, details, .btn).active,
.menu li > details > summary:active {
  background-color: transparent;
}

.custom_li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ham_menu {
  width: 40px;
  height: 2px;
  background-color: #aca9a5;
  position: relative;
  transition: all 0.1s;
}

.ham_menu::after {
  content: "";
  width: 40px;
  height: 2px;
  background-color: #aca9a5;
  position: absolute;
  left: 0;
  top: 10px;
  transition: all 0.4s;
}
.ham_menu::before {
  content: "";
  width: 40px;
  height: 2px;
  background-color: #aca9a5;
  position: absolute;
  left: 0;
  top: -10px;
  transition: all 0.4s;
}
.hamburger {
  padding-right: 0;
}

.hamburger:active .ham_menu,
.hamburger:focus .ham_menu {
  height: 0px;
}

.hamburger:active .ham_menu::after,
.hamburger:focus .ham_menu::after {
  rotate: -45deg;
  top: 0px;
}

.hamburger:active .ham_menu::before,
.hamburger:focus .ham_menu::before {
  rotate: 45deg;
  top: 0px;
}

.rs-input-group.rs-input-group-inside {
  border-radius: 6px !important;
}

.rs-picker-daterange > .rs-input-group.rs-input-group-inside .rs-input {
  font-size: 16px;
  padding-left: 16px;
}

.rs-picker-daterange
  > .rs-input-group.rs-input-group-inside
  .rs-input::placeholder {
  color: #aca9a5;
}

.leaflet-control-attribution {
  display: none;
}

.menu li > *:not(ul, .menu-title, details, .btn):active,
.menu li > *:not(ul, .menu-title, details, .btn).active,
.menu li > details > summary:active {
  color: #6c675b !important;
  text-decoration: none !important;
}

/* a:focus,
a:active {
  color: #fff !important;
  text-decoration: none !important;
} */

.rs-picker-daterange-panel {
  min-width: auto !important;
}

/* Rsuit  */
.rs-picker-toggle-wrapper {
  @media (max-width: 998px) {
    width: 100% !important;
  }
}
