.dual-calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.calendar-header button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.calendar-header button:hover {
  background-color: #0056b3;
}

.calendar {
  display: flex;
  gap: 20px;
}

.calendar-table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.calendar-cell {
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
}

.calendar-cell.booked {
  background-color: #aca9a5;
  color: white;
}

.calendar-cell.available {
  background-color: #aca9a5;
}

.calendar-cell.past {
  background-color: #aca9a5;
}

.legend {
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.legend-item {
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.legend-item.past {
  background-color: #ccc;
}

.legend-item.today {
  background-color: #999;
}

.legend-item.booked {
  background-color: #002c5f;
  color: white;
}

.calendar-cell.reserved {
  background-color: #d3d3d3; /* Light gray background */
  color: #000; /* Black text color */
  text-align: center;
  vertical-align: middle;
}

/* Make borders transparent */
.calendar-table {
  border-collapse: collapse; /* Ensures proper alignment of cells */
  width: 100%;
}

.calendar-cell {
  border: 2px solid #fff; /* Transparent borders */
  width: 40px; /* Adjust as needed */
  height: 40px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box; /* Ensures padding doesn't affect cell size */
}

/* Reserved dates styling */
.calendar-cell.reserved {
  background-color: #d3d3d3; /* Light gray background */
  color: #000;
}
