.pricing-table-container {
  margin: 20px 0;
  border-collapse: collapse;
  width: 100%;
}

.pricing-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  text-align: left;
  font-size: 14px;
}

.pricing-table td:last-child {
  text-align: right;
}

.total-row {
  font-weight: bold;
  font-size: 14px;
  border-top: 1px solid #aca9a5;
}
