.leaflet-container {
  width: 100%;
  height: 800px;

  @media (max-width: 992px) {
    height: 600px;
  }

  @media (max-width: 640px) {
    height: 400px;
  }
}
