.blog_banner {
  width: 100%;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.blog-content {
  flex: 2;
  max-width: 70%;
}

.blog-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.blog-date {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.blog-main-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.blog-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.additional-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.additional-image {
  width: calc(50% - 10px);
  height: auto;
  border-radius: 10px;
}

.recommended-blogs {
  flex: 1;
  max-width: 30%;
}

.recommended-blogs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recommended-blog {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.recommended-blog a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.recommended-blog-image {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin-right: 10px;
}

.recommended-blog-title {
  font-size: 0.9rem;
  line-height: 1.2;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-details-container {
    flex-direction: column;
  }

  .blog-content {
    max-width: 100%;
  }

  .recommended-blogs {
    max-width: 100%;
    margin-top: 20px;
  }
}

#blog_description p span {
  color: #aca9a5 !important;
  font-weight: 300 !important;
}

#blog_description h2 strong {
  color: #aca9a5 !important;
}

#blog_description p span {
  font-size: 15px;
}
