.custom_input {
  padding: 8px 6px;
  background-color: transparent;
  border-bottom: 2px solid black;
  width: 300px;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.custom_input:focus {
  outline: none;
}

.conact-banner {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.css-1dwdwzm-control {
  border: 0 !important;
  border-bottom: 1px solid #aca9a5 !important;
  border-radius: 0 !important;
}

.css-1dwdwzm-control:focus {
  border: 0 !important;
}

.css-1nyzm6b-control {
  border: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #aca9a5 !important;
  border-radius: 0 !important;
}
