.date-picker-container {
  position: relative;
  width: 100%;
  max-width: 380px;
}

.date-picker-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 50px;
  color: #aca9a5;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.placeholder {
  color: #aca9a5;
}

.clear-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #ccc;
}

.date-picker-popup {
  width: 100%;
  position: absolute;
  top: 55px;
  z-index: 10000;
  background-color: #fff;
  color: white;
  border-radius: 8px;
  padding: 10px;
}

#calendar {
  display: flex;
  flex-direction: column !important;
  width: 100%;
}
.calendar {
  display: flex;
  flex-direction: row !important;
  width: 100%;

  @media (max-width: 640px) {
    flex-direction: column !important;
  }
}

.month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.nav-arrow {
  background: none;
  border: none;
  color: #aca9a5;
  font-size: 18px;
  cursor: pointer;
}

.month {
  font-weight: normal;
  text-align: center;
  color: #aca9a5;
  font-size: 16px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
}

.day-label {
  text-align: center;
  font-weight: bold;
  color: #aaa;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 4px;
  color: #ccc;
  font-size: 15px;
}

.day:hover {
  background-color: #555;
  color: white;
  border-radius: 50%;
}

.empty-cell {
  background: none !important;
  pointer-events: none !important;
}

.selected-start,
.selected-end {
  background-color: #aca9a5;
  color: white;
  border-radius: 50%;
}

.hover-range {
  border: 1px dashed #aca9a5;
  border-radius: 50px;
}

.disabled-date {
  background-color: #f5f5f5;
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-date:hover {
  background-color: transparent;
}

.empty-cell {
  background-color: transparent;
  pointer-events: none;
}
