.services_banner {
  width: 100%;
  height: 70vh;
  background-image: url("../../Assets/Services/banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.service_card .service_card_details {
  opacity: 0%;
  transition: all ease 0.3;
  cursor: pointer;
}
.service_card:hover .service_card_details {
  opacity: 100%;
}
