/* DateRangePicker container */
.rs-picker-daterange .rs-picker-toggle {
  background-color: #f4f4f4; /* Light gray background */
  border-radius: 8px; /* Rounded corners */
  color: #555; /* Text color */
  font-size: 14px; /* Font size */
  border: 1px solid #d1d5db;
}

/* Calendar date cell */
.rs-calendar-table-cell-content {
  font-size: 14px; /* Font size for dates */
  color: #555; /* Default date color */
}

/* Selected date */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #aca9a5; /* Custom blue for selected date */
  color: #fff !important; /* White text on selected date */
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  background-color: #aca9a5; /* Custom blue for selected date */
  color: #fff !important; /* White text on selected date */
}

.rs-calendar-table-cell-in-range::before {
  background-color: #e3e2e2 !important;
  color: white;
}

/* OK button */
.rs-picker-toolbar-right .rs-btn-primary {
  background-color: #aca9a5; /* Green button */
  color: #fff; /* White text */
  border: none;
}

.rs-picker-toolbar-right .rs-btn-primary:hover {
  background-color: #8d8a87; /* Darker green on hover */
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content:hover {
  background: transparent;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #aca9a5 !important;
  -webkit-box-shadow: inset 0 0 0 1px #aca9a5 !important;
  box-shadow: inset 0 0 0 1px #aca9a5 !important;
  box-shadow: inset 0 0 0 1px #aca9a5 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  color: #fff;
  background-color: #aca9a5 !important;
  background-color: #aca9a5 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: rgb(from #d5d5d5 r g b / 50%) !important;
  background-color: #d5d5d5 !important;
  color: #fff !important;
  color: var(--rs-listbox-option-hover-text);
}

.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
  color: #fff;
  background-color: #d5d5d5 !important;
  background-color: #d5d5d5 !important;
  opacity: 0.3;
}

.rs-calendar-table-cell-day {
  font-size: 14px !important;
}
