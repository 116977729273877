.home_banner {
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    height: 40vh;
  }
  @media (max-width: 600px) {
    height: 60vh;
  }
}

.date-input-container {
  position: relative;
  display: inline-block;
}
.date-input-container input[type="date"] {
  position: relative;
  z-index: 2;
  background-color: transparent;
}
.date-input-container input[type="date"]:focus + .date-placeholder,
.date-input-container input[type="date"]:valid + .date-placeholder {
  display: none;
}
.date-placeholder {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  text-align: center;
  background-color: #fff;
  transform: translateY(-50%);
  color: black;
  pointer-events: none;
  z-index: 5;
}
