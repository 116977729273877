.about_banner {
  width: 100%;
  height: 70vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 998px) {
    height: 50vh;
  }
}

.luxury_homes {
  width: 100%;
  min-height: 600px;
  background-image: url("../../Assets/About/luxury homes section img.png");
  background-position: right;
  background-size: 50%;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    min-height: 500px;
  }
  @media (max-width: 882px) {
    background: none;
    height: auto;
  }
}
.luxury_concierge {
  width: 100%;
  min-height: 600px;
  background-image: url("../../Assets/About/Concierge img.png");
  background-position: left;
  background-size: 50%;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    min-height: 500px;
  }
  @media (max-width: 882px) {
    background: none;
    height: auto;
  }
}
.laundry_365 {
  width: 100%;
  min-height: 600px;
  background-image: url("../../Assets/About/Laundry img.png");
  background-position: right;
  background-size: 50%;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    min-height: 500px;
  }
  @media (max-width: 882px) {
    background: none;
    height: auto;
  }
}
.cleanit_365 {
  width: 100%;
  min-height: 600px;
  background-image: url("../../Assets/About/clean img.png");
  background-position: left;
  background-size: 50%;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    min-height: 500px;
  }
  @media (max-width: 882px) {
    background: none;
    height: auto;
  }
}
.maintenance_365 {
  width: 100%;
  min-height: 600px;
  background-image: url("../../Assets/About/maintenance img.png");
  background-position: right;
  background-size: 50%;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    min-height: 500px;
  }
  @media (max-width: 882px) {
    background: none;
    height: auto;
  }
}
