.sticky-section {
  position: sticky;
  top: 10px; /* Adjust the top value as needed */
  max-height: calc(100vh - 20px); /* Optional: Limit the height */
  overflow-y: auto; /* Optional: Add scrolling for long content */

  @media (max-width: 992px) {
    position: static;
  }

  @media (max-width: 640px) {
    position: static;
  }
}

.sticky-section {
  position: sticky;
  top: 80px; /* Keeps the section at the top when scrolling */
  overflow-y: scroll; /* Enables vertical scrolling */
  scrollbar-width: none; /* Hides scrollbar in Firefox */

  @media (max-width: 992px) {
    position: static;
  }

  @media (max-width: 640px) {
    position: static;
  }
}

.sticky-section::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}
