@import "rsuite/dist/rsuite.css";

.rs-input-group.rs-input-group-inside {
  height: 50px;
  border-radius: 0;
  border-color: #d1d5db;
}

.rs-input-group.rs-input-group-inside:focus {
  outline: none;
  border-color: black;
}

.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border-color: black;
}

#rs-date-range-input:focus {
  outline: none;
}

.custom-bullet {
  width: 32px !important;
  height: 2px !important;
  margin-top: 0 !important;
  background-color: white !important;
  border-radius: 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}

#custom_pagination_and_navigation {
  position: absolute;
  bottom: 100px;

  @media (max-width: 1024px) {
    bottom: 50px;
  }
  @media (max-width: 768px) {
    bottom: 10px;
  }
}

#guest-field {
  font-size: 16px;
  color: #aca9a5;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fdfcf5 !important;
}

.phone_modal {
  width: 100%;
  height: 100%;
  max-height: 100% !important;
  border-radius: 0;
}
